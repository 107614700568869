<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Create new template"
    primary-button-text="Create"
    :disabled="loading"
    secondary-button-text="Cancel"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
    @cancel="emits('update:modelValue', false)"
  >
    <form id="template-form" class="h-full" @submit.prevent>
      <p class="mb-4">
        Create new template for the list of templates. English language is mandatory for creating new template.
      </p>
      <UiInputSelect v-model="form.channel" :items="channels" label="Channel" name="Channel" disabled class="mb-3" />

      <UiInputTextField
        v-model="form.title"
        name="Title"
        label="Title"
        placeholder="Enter template title"
        class="mb-3"
        :error="useGetFieldErrors(v$, ['title'])"
      />

      <UiInputTextarea
        v-model="form.description"
        name="description"
        label="Description"
        placeholder="Enter template description"
        text-area-class="!min-h-[116px]"
        class="mb-4"
        :limit="false"
        :error="useGetFieldErrors(v$, ['description'])"
      />

      <hr class="mb-4 border-black-10" />

      <UiInputSelect
        v-model="form.language_id"
        :items="languageItems"
        label="Language"
        name="Language"
        placeholder="Choose language"
        disabled
        class="mb-3"
        :error="useGetFieldErrors(v$, ['language_id'])"
      />

      <UiInputTextarea
        v-model="form.text"
        name="text"
        label="Template text"
        placeholder="Enter text for the template in the correct language"
        text-area-class="!min-h-[116px]"
        :limit="false"
        :error="useGetFieldErrors(v$, ['text'])"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { useUiStore } from '~/store/ui'
import type { InputItem } from '@/types'

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'created'): void
}>()

const uiStore = useUiStore()

type Props = {
  modelValue: boolean
}
defineProps<Props>()

const form = ref({
  channel: 'sms',
  title: '',
  description: '',
  language_id: null,
  text: '',
})

const loading = ref(false)
const languageItems = ref<InputItem[]>([])
const channels: InputItem[] = [
  {
    value: 'sms',
    text: 'SMS',
  },
]

const rules = computed(() => ({
  title: { required: helpers.withMessage('The title is required', required) },
  description: { required: helpers.withMessage('The description is required', required) },
  language_id: { required: helpers.withMessage('Language is required', required) },
  text: { required: helpers.withMessage('The template text is required', required) },
}))

const v$ = useVuelidate(rules, form)

const getLanguages = async () => {
  languageItems.value = await useGetLanguageItems()
  form.value.language_id = languageItems.value.find((l: InputItem) => l.code === 'en')?.value
}

onNuxtReady(async () => await getLanguages())

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return

  try {
    loading.value = true
    const { title, description, channel, language_id: languageId, text } = form.value
    const body = {
      title,
      description,
      channel,
      translations: [{ language_id: languageId, text }],
    }
    await useCreateNotificationTemplate(body)
    emits('created')
    emits('update:modelValue', false)
  } catch (e) {
    uiStore.showSnackBanner('', 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
